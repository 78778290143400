import React from 'react'
import Selector from 'components/ui/Selector'
import { create } from 'zustand'

export const useDevice = create((set) => ({
  value: 'total',
  update: (value) => set({ value }),
}))

const DeviceDropdown = () => {
  const value = useDevice((state) => state.value)
  const update = useDevice((state) => state.update)
  return (
    <Selector
      datas={[
        { code: 'total', name: 'Total' },
        { code: 'iPhone', name: 'iPhone' },
        { code: 'iPad', name: 'iPad' },
      ]}
      update={update}
      value={value}
      labelName={'Device'}
      showLabel
    />
  )
}
export default DeviceDropdown
