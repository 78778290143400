import { tokenFetcher, useAuthStore } from 'hooks'
import React, { useEffect } from 'react'
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'
import { Button } from '@/components/ui/button'

const WithCode = ({ code }) => {
  const { setToken } = useAuthStore()
  const navigate = useNavigate()
  const { trigger, isMutating, isError } = useSWRMutation(
    '/login',
    tokenFetcher,
    {
      onSuccess: (data) => {
        if (data) {
          setToken(data)
          navigate('/roi')
        }
      },
      onError: (error) => {
        navigate('/login')
      },
    },
  )

  useEffect(() => {
    if (code) {
      trigger({ code })
    }
  }, [code, trigger, setToken, navigate])

  return (
    <div>
      <p>{code}</p>
      {isMutating && <p>login ing.</p>}
      {isError && <p>login error</p>}
    </div>
  )
}

const LoginPage = () => {
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const queryParams = Object.fromEntries(searchParams.entries())
  if (queryParams.code) {
    return (
      <div className="flex flex-col max-auto items-center">
        <WithCode code={queryParams.code} />
      </div>
    )
  }

  const from = location.state?.from?.pathname || '/'
  const appId = `cli_a42177b6ad4e500d`
  const redirect_uri = `${window.location.origin}/login`
  const href = `https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${encodeURI(
    redirect_uri,
  )}&app_id=${appId}&state=${from}`

  return (
    <div className="flex flex-col mx-auto items-center">
      <h1 className="my-5">Welcome to Monday.</h1>
      <a href={href}>
        <Button variant="outline">Login use feishu</Button>
      </a>
    </div>
  )
}

export default LoginPage
