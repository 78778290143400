import CountryDropdown, { useCountry } from 'components/DropdownCountry'
import MyDatePicker, { useDatePicker } from 'components/MyDatePicker'
import React, { useMemo, useState } from 'react'
import useSWR from 'swr'
import DeviceDropdown, { useDevice } from './DropdownDevice'
import SkuDropdown, { createSkuIdStore } from './DropdownSku'

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import MyTable, {
  MyTableColumnVisible,
  createColumnVisibilityStore,
  useColumnFilters,
} from 'components/MyTable'
import {
  billRetryColumn,
  cancelColumn,
  dnuColumn,
  eventAtColumn,
  freeTryColumn,
  gracePeriodColumn,
  paidColumn,
  reactivateColumn,
  refundColumn,
  renewColumn,
} from './columns'

const useColumnVisibilityStore = createColumnVisibilityStore({}, 'event1')

const useSkuId = createSkuIdStore('1634387596', 'event1')

const columns = [
  eventAtColumn,
  dnuColumn,
  freeTryColumn,
  paidColumn,
  refundColumn,
  renewColumn,
  cancelColumn,
  billRetryColumn,
  reactivateColumn,
  gracePeriodColumn,
]

const Event1 = () => {
  const country = useCountry((state) => state.value)
  const skuId = useSkuId((state) => state.value)
  const updateSkuId = useSkuId((state) => state.update)
  const { startDate, endDate } = useDatePicker((state) => ({
    startDate: state.startDate,
    endDate: state.endDate,
  }))

  const device = useDevice((state) => state.value)

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const { columnFilters } = useColumnFilters()
  const [rowSelection, setRowSelection] = useState({})

  const { data: events } = useSWR([
    '/apple/events',
    { pid: 12, country, startDate, endDate, skuId },
  ])
  const { data: dnus } = useSWR([
    '/apple/dnus',
    { pid: 12, country, startDate, endDate },
  ])
  const data = useMemo(() => {
    const uniqueAts = Array.from(new Set(events?.map((item) => item.eventAt)))
    return uniqueAts.map((eventAt) => ({
      eventAt,
      events: events?.filter((item) => item.eventAt === eventAt),
      freeTry: events?.find(
        (item) =>
          item.eventAt === eventAt && item.event === 'Start Introductory Offer',
      ),
      dnu: dnus?.find((item) => item.date === eventAt)?.[device],
    }))
  }, [events, dnus, device])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRowClick = (row) => {
    const newSelection = { ...rowSelection }
    if (newSelection[row.id]) {
      delete newSelection[row.id]
    } else {
      newSelection[row.id] = true
    }
    setRowSelection(newSelection)
  }

  return (
    <>
      <div className="flex items-center gap-4 py-4 sm:gap-2 sm:py-2 flex-wrap">
        <CountryDropdown />
        <MyDatePicker />
        <SkuDropdown value={skuId} update={updateSkuId} />
        <DeviceDropdown />
        <MyTableColumnVisible table={table} />
      </div>
      <MyTable table={table} onRowClick={handleRowClick} />
    </>
  )
}

export default Event1
