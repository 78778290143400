import { Countries } from 'comm'
import React from 'react'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import Dropdown from './ui/Selector'

export const createCountryStore = (init = 'total', key = '') =>
  create(
    persist((set) => ({ value: init, update: (value) => set({ value }) }), {
      name: `country-storage-${key}`,
      storage: createJSONStorage(() => localStorage),
    }),
  )

export const useCountry = create((set) => ({
  value: 'total',
  update: (value) => set({ value }),
}))

const DropdownCountry = () => {
  const value = useCountry((state) => state.value)
  const update = useCountry((state) => state.update)
  return (
    <Dropdown
      datas={Countries}
      showLabel
      labelName="Country"
      value={value}
      update={update}
    />
  )
}

export default DropdownCountry
