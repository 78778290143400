import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import React from 'react'
import { NavLink } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet'
import { cn } from '@/lib/utils'
import { useAuthStore } from 'hooks'
import { PanelLeft } from 'lucide-react'
import { RoleAdmin } from './RoleFilter'

const NavItem = ({ menu }) => {
  return (
    <NavLink
      to={menu.path}
      className={({ isActive }) =>
        cn(`transition-colors`, {
          'text-primary font-bold': isActive,
          'text-foreground/60 hover:text-primary': !isActive,
        })
      }
    >
      {menu.title}
    </NavLink>
  )
}

const menus = [
  { title: 'Roi', path: '/roi' },
  { title: 'Apple', path: '/apple' },
  { title: 'Roas', path: '/roas' },
  { title: 'ER', path: '/er' },
]

const Nav = () => {
  const user = useAuthStore((state) => state.auth?.user)
  const removeToken = useAuthStore((state) => state.removeToken)

  if (!user) {
    return null
  }

  return (
    <header className="sticky top-0 z-50 w-full border-b border-border/40 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 p-1 gap-2">
      <div className="container mx-auto flex h-14 items-center">
        <div className="mr-4 hidden lg:flex">
          <a className="mr-6 flex items-center space-x-2" href="/">
            <span className="hidden font-bold sm:inline-block text-primary">
              Monday
            </span>
          </a>
          <nav className="flex items-center gap-4 lg:gap-6">
            {menus.map((menu) => (
              <NavItem key={menu.path} menu={menu} />
            ))}

            <RoleAdmin>
              <NavItem menu={{ path: 'admin', title: 'Admin' }} />
            </RoleAdmin>
          </nav>
        </div>
        <ToggleButton />
        <div className="flex flex-1 items-center justify-between space-x-2 md:justify-end">
          <div className="w-full flex-1 md:w-auto md:flex-none"></div>
          <nav className="flex items-center">
            <Avatar
              className="w-8 h-8 border-2 border-transparent hover:border-blue-500 transition-all duration-300"
              onClick={removeToken}
            >
              <AvatarImage src={user.avatarThumb} alt="@shadcn" />
              <AvatarFallback>Log Out</AvatarFallback>
            </Avatar>
          </nav>
        </div>
      </div>
    </header>
  )
}

const SheetNav = ({ menu }) => {
  return (
    <NavLink
      to={menu.path}
      className={({ isActive }) =>
        cn(
          'flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground',
          isActive && 'text-primary',
        )
      }
    >
      {menu.title}
    </NavLink>
  )
}

const ToggleButton = () => {
  return (
    <Sheet>
      <SheetTrigger>
        <Button
          className={cn('lg:hidden h-9 py-2 mr-2 px-0 w-auto')}
          variant="ghost"
          size="icon"
        >
          <PanelLeft className="w-5 h-5" />
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="sm:max-w-xs">
        <nav className="grid gap-4 text-lg font-medium">
          {menus.map((menu) => (
            <SheetNav key={menu.path} menu={menu} />
          ))}
          <RoleAdmin>
            <SheetNav menu={{ path: 'admin', title: 'Admin' }} />
          </RoleAdmin>
        </nav>
      </SheetContent>
    </Sheet>
  )
}

export default Nav
