import MyLink from 'components/MyLink'
import { formatPercent } from 'utils'
import { useDevice } from './DropdownDevice'

const Td3 = ({ event, freeTry, dnu }) => {
  const device = useDevice((state) => state.value)
  const eventValue = event?.[device]
  const freeTryValue = freeTry?.[device]
  const rate = formatPercent(eventValue / freeTryValue, 2, 2)
  const avg = formatPercent(eventValue / dnu, 2, 2)
  if (!eventValue) return null
  return (
    <div className="flex flex-col text-right">
      <div>{eventValue}</div>
      <div className="text-xs text-muted-foreground">{rate}</div>
      <div className="text-xs text-muted-foreground">{avg}</div>
    </div>
  )
}

const GroupHeader = ({ children }) => {
  return (
    <div className="">
      {children}
      <span className="text-xs ms-1 text-muted-foreground">来自于</span>
    </div>
  )
}

export const eventAtColumn = {
  id: 'eventAt',
  header: 'Event At',
  accessorFn: (row) => row.eventAt,
  cell: (row) => {
    const eventAt = row.getValue()
    return <MyLink to={`/apple/events1/${eventAt}/roadmap`}>{eventAt}</MyLink>
  },
}

export const subAtColumn = {
  id: 'subAt',
  header: 'Sub At',
  accessorFn: (row) => row.subAt,
  cell: (row) => {
    const subAt = row.getValue()
    return <MyLink to={`/apple/events2/${subAt}/roadmap`}>{subAt}</MyLink>
  },
}

export const dnuColumn = {
  id: 'dnu',
  header: 'DNU',
  accessorFn: (row) => row.dnu,
  cell: (row) => {
    const dnu = row.getValue()
    return <div>{dnu}</div>
  },
}

export const eventColumn = (event) => ({
  id: event.id,
  header: event.name,
  accessorFn: (row) => {
    const e = row.events?.find((e) => e.event === event.id)
    const freeTry = row.freeTry
    const dnu = row.dnu
    return { event: e, freeTry, dnu }
  },
  cell: (row) => {
    const { event, freeTry, dnu } = row.getValue()
    return <Td3 event={event} freeTry={freeTry} dnu={dnu}></Td3>
  },
})

export const paidColumn = {
  id: 'paid',
  header: () => <GroupHeader>正式付费</GroupHeader>,
  columns: [
    ...[
      { id: 'Paid Subscription from Introductory Offer', name: '试用结束' },
      { id: 'Renewal from Billing Retry', name: '账单重试' },
    ].map((e) => eventColumn(e)),
  ],
}

export const renewColumn = {
  id: 'renew',
  header: () => <GroupHeader>续订</GroupHeader>,
  columns: [
    ...[
      { id: 'Renew', name: '续订' },
      { id: 'Renewals from Grace Period', name: '宽限期' },
    ].map((e) => eventColumn(e)),
  ],
}

export const cancelColumn = {
  id: 'cancel',
  header: () => <GroupHeader>取消</GroupHeader>,
  columns: [
    ...[
      { id: 'Cancel', name: '直接取消' },
      { id: 'Canceled from Billing Retry', name: '账单重试' },
    ].map((e) => eventColumn(e)),
  ],
}

export const freeTryColumn = eventColumn({
  id: 'Start Introductory Offer',
  name: '试用期',
})

export const refundColumn = {
  id: 'refundGroup',
  header: '退款',
  columns: [eventColumn({ id: 'Refund', name: '' })],
}

export const billRetryColumn = {
  id: 'billRetry',
  header: () => <GroupHeader>账单重试</GroupHeader>,
  columns: [
    ...[
      { id: 'Billing Retry from Introductory Offer', name: '试用期' },
      { id: 'Billing Retry from Paid Subscription', name: '正式付费' },
      { id: 'Billing Retry from Grace Period', name: '宽限期' },
    ].map((e) => eventColumn(e)),
  ],
}

export const reactivateColumn = {
  id: 'reactivate',
  header: () => <GroupHeader>重新激活</GroupHeader>,
  columns: [
    ...[
      { id: 'Reactivate', name: '重新激活' },
      { id: 'Reactivate with Crossgrade', name: '跨级升级' },
    ].map((e) => eventColumn(e)),
  ],
}

export const gracePeriodColumn = {
  id: 'gracePeriod',
  header: () => <GroupHeader>进入宽限期</GroupHeader>,
  columns: [
    ...[{ id: 'Grace Period from Paid Subscription', name: '正式付费' }].map(
      (e) => eventColumn(e),
    ),
  ],
}
