import React, { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'

import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import MyTable from 'components/MyTable'
import {
  dateColumn,
  d1Column,
  d2Column,
  d3Column,
  d4Column,
  d5Column,
  d6Column,
  d7Column,
} from './columns'

const Detail = () => {
  const { id } = useParams()
  const { data: roas } = useSWR([`/camprois/${id}`])

  useEffect(() => {
    console.log(`id render`)
  }, [id])

  const columns = useMemo(
    () => [
      dateColumn,
      d1Column,
      d2Column,
      d3Column,
      d4Column,
      d5Column,
      d6Column,
      d7Column,
    ],
    [],
  )
  const data = useMemo(() => roas ?? [], [roas])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {},
  })

  const camp = data?.[0]

  return (
    <div>
      <h3 className="text-lg font-medium flex gap-4">
        <span>{camp?.campName}</span>
        <span>{camp?.country}</span>
        <span>{camp?.pid}</span>
      </h3>
      <MyTable table={table} />
    </div>
  )
}

export default Detail
