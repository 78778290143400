import DropdownCountry, { useCountry } from 'components/DropdownCountry'
import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import useSWR from 'swr'
import DeviceDropdown from './DropdownDevice'
import SkuDropdown, { createSkuIdStore } from './DropdownSku'

import dayjs from 'dayjs'

import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import MyTable, {
  MyTableColumnVisible,
  createColumnVisibilityStore,
  useColumnFilters,
} from 'components/MyTable'

import {
  billRetryColumn,
  cancelColumn,
  freeTryColumn,
  gracePeriodColumn,
  paidColumn,
  reactivateColumn,
  refundColumn,
  renewColumn,
} from './columns'

const subAtColumn = {
  id: 'subAt',
  header: 'Sub At',
  accessorFn: (row) => {
    const subAt = row.subAt
    const eventAt = row.eventAt
    const eventDay = dayjs(eventAt.toString(), 'YYYYMMDD')
    const subDay = dayjs(subAt.toString(), 'YYYYMMDD')
    const diffDays = eventDay.diff(subDay, 'day')
    return { subAt, eventAt, diffDays }
  },
  cell: (row) => {
    const { subAt, diffDays } = row.getValue()
    return (
      <div>
        {subAt} ({diffDays}day)
      </div>
    )
  },
}

const useColumnVisibilityStore = createColumnVisibilityStore(
  {},
  'event1roadmap',
)
const useSkuId = createSkuIdStore('1634387596', 'event1roadmap')

const EventRoadmap = () => {
  const { eventAt } = useParams()
  const skuId = useSkuId((state) => state.value)
  const updateSkuId = useSkuId((state) => state.update)
  const country = useCountry((state) => state.value)
  const { data: events } = useSWR([
    `/apple/events1/${eventAt}/roadmap`,
    { pid: 12, country, skuId },
  ])

  const { columnVisibility, setColumnVisibility } = useColumnVisibilityStore()
  const { columnFilters } = useColumnFilters()
  const [rowSelection, setRowSelection] = useState({})

  const data = useMemo(() => {
    const uniqueAts = Array.from(new Set(events?.map((item) => item.subAt)))
    return uniqueAts.map((subAt) => ({
      eventAt,
      subAt,
      events: events?.filter((item) => item.subAt === subAt),
      freeTry: events?.find(
        (item) => item.event === 'Start Introductory Offer',
      ),
    }))
  }, [eventAt, events])

  const columns = useMemo(() => {
    const rows = []
    rows.push(subAtColumn)
    // rows.push(dnuColumn)
    rows.push(freeTryColumn)
    rows.push(paidColumn)
    rows.push(refundColumn)
    rows.push(renewColumn)
    rows.push(cancelColumn)
    rows.push(billRetryColumn)
    rows.push(reactivateColumn)
    rows.push(gracePeriodColumn)
    return rows
  }, [])

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  })

  const handleRowClick = (row) => {
    const newSelection = { ...rowSelection }
    if (newSelection[row.id]) {
      delete newSelection[row.id]
    } else {
      newSelection[row.id] = true
    }
    setRowSelection(newSelection)
  }

  return (
    <>
      <div className="flex items-center gap-4 py-4 flex-wrap sm:gap-2 sm:py-2">
        <DropdownCountry />
        <SkuDropdown value={skuId} update={updateSkuId} />
        <DeviceDropdown />
        <MyTableColumnVisible table={table} />
      </div>
      <div> The event is at {eventAt}. On which day is the user from?</div>
      <MyTable table={table} onRowClick={handleRowClick} />
    </>
  )
}

export default EventRoadmap
